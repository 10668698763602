

function Footer() {
  return (
    <footer expand="lg">
        <p>© 2024 Omer Faruk Sivri</p>
    </footer>
  );
}

export default Footer;